<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">KKM Progja</h5>
              <div class="button"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="*">Pilih Tahun Ajaran</option>
                    <option v-for="tahunajaran in tah" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush" style="font-size: 12px;width:40%">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th style="width:35%" class="text-center">Tahun ajaran</th>
                    <th style="width:10%"><center>Unit</center></th>
                    <th style="width:3%">KKM Progja</th>
                  </tr>
                </thead>
                <tbody>
                    
                  <tr v-for="(m, index) in listprogja.data" :key="m.id" style="height: 1px">
                    <td align="center">{{ tas.desc }}</td>
                    <td align="center">{{ m.code }}</td>
                    <td>

                      <input type="text" :value=" m.kkmprogja ? m.kkmprogja.kkm_progja:0 " :data-id="m.id" class="form-control"
                      @input="updateWeight"
                      @keydown.enter="focusNextInput($event, m, index)"
                      style="text-align: center;"
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router"; // Correct import from vue-router

export default {
  name: "KkmprogjaIndex",

  setup() {
    const router = useRouter();
    const validation = ref([]);
    const store = useStore();
    const data = reactive({
      perPage: 10,
      search: "",
      unit: "",
      id_unit: "",
      unitlevel: "",
      id_unitlevel: "",
      kelas_id: "",
      jenis_semester: "",
      pages: "kkmprogja-view",
    });
    

    onMounted(async () => {
      await store.dispatch("kkmprogja/getData");
      await store.dispatch("tahunajaran/getAll"); 
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch("unitlevel/getAll");   
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const listprogja = computed(() => {
      return store.getters["kkmprogja/getData"];
    });

    const focusNextInput = (e, m, index) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-control");
        const currentIndex = Array.from(inputs).indexOf(targetInput);
        const columns = 3; // Number of columns in your table

        // Calculate the next index based on the current column
        const nextIndex = currentIndex + 1 < inputs.length ? currentIndex + 1 : (index + 1) * columns;

        inputs[nextIndex].focus();
      }
    };

    async function updateWeight(e) {
      const inputValue = e.target.value;
      const id = e.target.getAttribute('data-id');

      let formData = new FormData();
      formData.append("kkm_progja", inputValue);
      formData.append("unit_id", id);
      formData.append("_method", "POST");
      console.log("formData "+formData);
      // console.log("id "+id);

      await store
        .dispatch("kkmprogja/update", {
          medicalId: id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });
    }

    

    const kkmprogja = computed(() => {
      return store.getters["kkmprogja/getData"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    
    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const kelas = computed(() => {
      return store.getters["kelas/getClassname"];
    });

    const tah = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });

    const changeTa = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("kkmprogja/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        id_ta: data.ta,
      });
    };

    setNavPills();
    setTooltip(store.state.bootstrap);



    return {
      
      listprogja,
      perPageOptions,
      data,
      
      updateWeight,
     
      unitlevels,
      
      kkmprogja,
      user,
      focusNextInput,
      kelas,

      tas,
      tah,
      changeTa
    };
  },
};
</script>
