import Api from '../../api/Api'
const siswa = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        datalulus: [],
        datasemesterdepan: [],
        totaltk:{},
        totalsd:{},
        totalsmp:{},
        totalsma:{},
        datanilaisikap:[],
        datanilaisikapekskul:[],
    },

    //mutations
    mutations: {
      
        SET_DATA(state, datas) {
            state.datas = datas
        },

        SET_DATA_NILAISIKAP(state, datanilaisikap){
            state.datanilaisikap = datanilaisikap
        },

        SET_DATA_NILAISIKAP_EKSKUL(state, datanilaisikapekskul){
            state.datanilaisikapekskul = datanilaisikapekskul
        },

        SET_DATA_TK(state, totaltk) {
            state.totaltk = totaltk
        },

        SET_DATA_SD(state, totalsd) {
            state.totalsd = totalsd
        },

        SET_DATA_SMP(state, totalsmp) {
            state.totalsmp = totalsmp
        },

        SET_DATA_SMA(state, totalsma) {
            state.totalsma = totalsma
        },



        SET_DATA_LULUS(state, datalulus) {
            state.datalulus = datalulus
        },
      

        SET_DATA_SEMESTER_DEPAN(state, datasemesterdepan) {
            state.datasemesterdepan = datasemesterdepan
        },
    },

    _actions: {
        getSiswaKelas({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasId = payload ? payload.kelasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswa/kelas/${kelasId}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    console.log('ini soswa',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getSiswaNilaiSikap({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasId = payload ? payload.kelasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswa/kelasnilaisikap/${kelasId}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // console.log('ini soswa',response.data.data)
                    commit('SET_DATA_NILAISIKAP', response.data.data)
                })
        },

        getSiswaNilaiSikapEkskul({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasId = payload ? payload.kelasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswa/kelasnilaisikapekskul/${kelasId}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // console.log('ini soswa',response.data.data)
                    commit('SET_DATA_NILAISIKAP_EKSKUL', response.data.data)
                })
        },

        getSiswaKelasData({ commit }, payload) {

            //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let kelasId = payload ? payload.kelasId : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswa/kelas/${kelasId}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        saveRaport({ commit }, formData) {
          

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/siswa/uploadraport', formData)

                    .then(response => {

                     
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        updateStatus({ commit }, formData) {
          

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/updatestatusnaikkelas', formData)

                    .then(response => {

                     
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getSiswaLulus({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasId = payload ? payload.kelasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswa/kelulusan/kelas/${kelasId}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getSiswaLulusData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let kelasId = payload ? payload.kelasId : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswa/kelulusan/kelas/${kelasId}?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    //console.log('ini siswa lulult',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        saveSkl({ commit }, formData) {
          

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/siswa/uploadskl', formData)

                    .then(response => {
                        commit('SET_DATA', response.data.data)
                        resolve(response)

                    }).catch(error => {

                        
                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

    

        updateOrder({ commit }, formData) {
          

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/siswa/updateorder', formData)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        updateNisn({ commit }, formData) {
          

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/siswaaktif/updatenisn', formData)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getSiswaAktif({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let unit = payload ? payload.unit : '';
            let unitlevel = payload ? payload.unitlevel : '';
            let kelas = payload ? payload.kelas : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let guru = payload ? payload.guru : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&kelas=${kelas}&unitlevel=${unitlevel}&unit=${unit}&guru=${guru}`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getSiswaAktifCari({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let unit = payload ? payload.unit : '';
            let unitlevel = payload ? payload.unitlevel : '';
            let kelas = payload ? payload.kelas : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let guru = payload ? payload.guru : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/cari?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&kelas=${kelas}&unitlevel=${unitlevel}&unit=${unit}&guru=${guru}`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        lapkbtkbulanan({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let unit = payload ? payload.unit : '';
            let unitlevel = payload ? payload.unitlevel : '';
            let kelas = payload ? payload.kelas : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let guru = payload ? payload.guru : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/lapkbtkbulanan?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&kelas=${kelas}&unitlevel=${unitlevel}&unit=${unit}&guru=${guru}`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA', response.data)
                })
        },

        SiswaAktifSemesterDepan({ commit }, payload) {
            // //console.log('ini parsing',payload);
        
            let kelas = payload ? payload.kelas : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`siswaactive/siswaaktifsemesterdepan?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&kelas=${kelas}`)
                .then(response => {
                    //  //console.log('ini siswa aktif semester depan',response.data.data)
                    commit('SET_DATA_SEMESTER_DEPAN', response.data.data)
                })
        },

        SiswaLulusSemesterIni({ commit }, payload) {
            // //console.log('ini parsing',payload);
        
         
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let unitlevel = payload ? payload.unitlevel : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswalulus?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unitlevel=${unitlevel}`)
                .then(response => {
                     //console.log('ini lulus',response.data.data)
                    commit('SET_DATA_LULUS', response.data.data)
                })
        },

        SiswaAktifSemesterActive({ commit }, payload) {
            // //console.log('ini parsing',payload);
        
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let unitlevel = payload ? payload.unitlevel : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaactive/siswaaktifsemesteractive?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unitlevel=${unitlevel}`)
                .then(response => {
                     //console.log('ini siswa aktif',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        SiswaAktifByUnitlevel({ commit }, payload) {
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let unitlevel = payload ? payload.unitlevel : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaactivebyunitlevel?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unitlevel=${unitlevel}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        SiswaBarudanPindahan({ commit }, payload) {
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let unit = payload ? payload.unit : '';
            let unitlevel = payload ? payload.unitlevel : '';
            let kelas = payload ? payload.kelas : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswabarudanpindahan?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&kelas=${kelas}&unitlevel=${unitlevel}&unit=${unit}`)
                .then(response => {
                    //console.log('ini siswa baru',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getSiswaAktifKbtk({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/totalkbtk`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA_TK', response.data.data)
                })
        },

        getSiswaAktifSd({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/totalsd`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA_SD', response.data.data)
                })
        },

        getSiswaAktifSmp({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/totalsmp`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA_SMP', response.data.data)
                })
        },

        getSiswaAktifSma({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/totalsma`)
                .then(response => {
                    //console.log('siswa aktif',response.data.data)
                    commit('SET_DATA_SMA', response.data.data)
                })
        },

        getSiswaAktifin({ commit }, payload) {
            // console.log('ini getSiswaAktifin ',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let id_ekskul_aktif = payload ? payload.id_ekskul_aktif:''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/wherein?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&id_ekskul_aktif[]=${id_ekskul_aktif}`)
                .then(response => {
                    commit('SET_DATA', response.data.data)
                })
        },

        getSiswaAktifinDetail({ commit }, payload) {
            // console.log('ini getSiswaAktifin ',payload);
            let id = payload ? payload.id:''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/siswaaktif/detail/${id}`)
                .then(response => {
                    commit('SET_DATA', response.data.data)
                })
        },
        

        


    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getSiswaKelas(state) {
            return state.datas
        },

        getSiswaNilaiSikap(state){
            return state.datanilaisikap
        },

        getSiswaNilaiSikapEkskul(state){
            return state.datanilaisikapekskul
        },

        getSiswaKelasData(state) {
            return state.datas
        },
        getSiswaLulus(state) {
            return state.datas
        },


        getSiswaLulusData(state) {
            return state.datas
        },

        getSiswaAktif(state) {
            return state.datas
        },

        getSiswaAktifCari(state) {
            return state.datas
        },

        getSiswaAktifKbtk(state) {
            return state.totaltk
        },

        getSiswaAktifSd(state) {
            return state.totalsd
        },

        getSiswaAktifSmp(state) {
            return state.totalsmp
        },

        getSiswaAktifSma(state) {
            return state.totalsma
        },

        lapkbtkbulanan(state) {
            return state.datas
        },

        SiswaAktifSemesterDepan(state) {
            return state.datasemesterdepan
        },

        SiswaLulusSemesterIni(state) {
            return state.datalulus
        },

        SiswaAktifSemesterActive(state) {
            return state.datas
        },

        SiswaAktifByUnitlevel(state) {
            return state.datas
        },

        SiswaBarudanPindahan(state){
            return state.datas
        },

        getSiswaAktifin(state) {
            return state.datas
        },

        getSiswaAktifinDetail(state) {
            return state.datas
        },



    }

}

export default siswa