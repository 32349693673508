import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import auth from './module/auth';
import menu from './module/menu';
import permission from './module/permission';
import roles from './module/roles';
import user from './module/user';
import units from './module/units';
import jadwalpelaksanaan from './module/jadwalpelaksanaan';
import tahunajaran from './module/tahunajaran';
import reg from './module/reg';

// import uploadraport from './module/uploadraport';
// import uploadkelulusan from './module/uploadkelulusan';
import aturannilai from './module/aturannilai';
import bebanmengajar from './module/bebanmengajar'
import daftarinforegistrasi from './module/daftarinforegistrasi'
import daftarjenispekerjaan from './module/daftarjenisperkerjaan'
import daftarnamasekolah from './module/daftarnamasekolah'
import generatenis from './module/generatenis'
import matapelajaran from './module/matapelajaran';
import dashboard from './module/dashboard'
import global from './module/global'
import guru from './module/guru'
import cp from './module/cp'
import indikator from './module/indikator'
import infregusers from './module/infregusers'
import kategoripenilaian from './module/kategoripenilaian'
import kategorisikap from './module/kategorisikap'
import groupkategorisikap from './module/groupkategorisikap'
import keterangannilai from './module/keterangannilai'
import kelas from './module/kelas'
import mapelceklis from './module/mapelceklis'
import element from './module/element'
import masterkelas from './module/masterkelas'
import modatransportasi from './module/modatransportasi'
import naikkelas from './module/naikkelas'
import nilaisikap from './module/nilaisikap'
import elementp5 from './module/elementp5'
import targetp5 from './module/targetp5'
import absenkelas from './module/absenkelas'
import nilaibk from './module/nilaibk'
import nilaiwalas from './module/nilaiwalas'
import dimensip5 from './module/dimensip5'
import nilai from './module/nilai'
import nilaipernyataan from './module/nilaipernyataan'
import orangtua from './module/orangtua'
import pengajar from './module/pengajar'
import pindahsekolah from './module/pindahsekolah'
import ppdb from './module/ppdb'
import relasikeluarga from './module/relasikeluarga'
import subkategorisikap from './module/subkategorisikap'
import subkategoripenilaian from './module/subkategoripenilaian'
import tinggalkelas from './module/tinggalkelas'
import unitlevel from './module/unitlevel'
import settingapp from "./module/settingapp";
import siswa from "./module/siswa";
import medicalinfo from "./module/medicalinfo";
import typay from "./module/typay";
import workpriority from "./module/workpriority";
import work from "./module/work";
import typaynominal from "./module/typaynominal";
import importnis from "./module/importnis";
import nilaiceklis from "./module/nilaiceklis";
import ekskul from './module/ekskul';
import ekskulaktif from './module/ekskulaktif';
import narasikb from './module/narasikb';
import akhirkbtk from './module/akhirkbtk';
import masterp5 from './module/masterp5';
import nilaip5 from './module/nilaip5';
import kkmprogja from './module/kkmprogja';

export default createStore({
  modules: {
    auth,
    importnis,
    medicalinfo,
    menu,
    permission,
    elementp5,
    targetp5,
    keterangannilai,
    indikator,
    roles,
    dimensip5,
    user,
    nilaisikap,
    nilaiceklis,
    absenkelas,
    nilaibk,
    nilaiwalas,
    units,
    element,
    cp,
    nilaip5,
    jadwalpelaksanaan,
    tahunajaran,
    groupkategorisikap,
    reg,
    typay,
    typaynominal,
    workpriority,
    work,
    dashboard,
    masterp5,
    // uploadraport,
    // uploadkelulusan,
    aturannilai,
    bebanmengajar,
    daftarinforegistrasi,
    daftarjenispekerjaan,
    daftarnamasekolah,
    generatenis,
    matapelajaran,
    global,
    guru,
    infregusers,
    kategoripenilaian,
    kategorisikap,
    kelas,
    masterkelas,
    modatransportasi,
    naikkelas,
    nilai,
    nilaipernyataan,
    orangtua,
    pengajar,
    pindahsekolah,
    ppdb,
    mapelceklis,
    relasikeluarga,
    subkategoripenilaian,
    subkategorisikap,
    tinggalkelas,
    unitlevel,
    settingapp,
    ekskul,
    ekskulaktif,
    siswa,
    narasikb,
    akhirkbtk,
    kkmprogja

  },
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: "",
    sidebarType: "bg-white",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
    bootstrap,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector("#app");
      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && window.innerWidth < 1200) {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("setSidebarType", payload);
    },
  },
  getters: {},
});
