<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data User</h5>
              <router-link
                v-if="datapermissions.includes('user-create')"
                to="/user/tambah"
                class="btn btn-primary btn-sm"
                >Tambah</router-link
              >
            </div>
          </div>

          <div class="card-body">
            <div class="row"></div>
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select
                    v-model="data.perPage"
                    class="form-control form-control-sm ml-2"
                    @change="changePerPage"
                  >
                    <option
                      v-for="option in perPageOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input
                  type="text"
                  class="form-control mr-2"
                  v-model="data.search"
                  @keypress.enter="searchData"
                  placeholder="pencarian"
                  aria-label="pencarian"
                  aria-describedby="button-addon2"
                />
                <button
                  @click="searchData"
                  class="btn btn-success mb-0 ms-2"
                  type="button"
                  id="button-addon2"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div v-if="users.data">
              <div class="table-responsive">
                <table
                  id="datatable-search"
                  class="table table-flush small-table"
                  style="font-size: 12px"
                >
                  <thead class="thead-light">
                    <tr style="max-height: 5px">
                      <th>Username</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>#</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="m in users.data" :key="m.id" style="height: 1px">
                      <td>{{ m.username }}</td>
                      <td>{{ m.email }}</td>
                      <td>
                        <ul v-if="m.roles.length === 0">
                          <li>belum ada roles</li>
                        </ul>
                        <ul v-else>
                          <li v-for="(a, index) in m.roles" :key="index">
                            {{ a.name }}
                          </li>
                        </ul>
                      </td>

                      <td>
                        <router-link
                          v-if="datapermissions.includes('user-edit')"
                          :to="{
                            name: 'admin-user-edit',
                            params: { id: m.id },
                          }"
                          class="btn btn-success btn-xs m-0 me-2"
                        >
                          <i class="fas fa-pen"></i
                        ></router-link>
                        <button
                          v-if="datapermissions.includes('user-delete')"
                          class="btn btn-danger btn-xs m-0"
                          @click="confirmDelete(m.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <ContentLoader />
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ users.to }} dari {{ users.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li
                  class="page-item"
                  :class="{ disabled: users.current_page === 1 }"
                >
                  <a
                    class="page-link"
                    @click="changePage(users.current_page - 1)"
                    href="#"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li
                  v-for="page in users.links"
                  :key="page"
                  :class="{ 'page-item': true, active: page.active === true }"
                >
                  <a
                    class="page-link"
                    @click="changePage(page.label)"
                    href="#"
                    v-if="
                      page.label != 'pagination.previous' &&
                      page.label != 'pagination.next'
                    "
                    >{{ page.label }}</a
                  >
                </li>
                <li
                  class="page-item"
                  :class="{ disabled: users.current_page === users.last_page }"
                >
                  <a
                    class="page-link"
                    @click="changePage(users.current_page + 1)"
                    href="#"
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "UserIndex",

  components: {
    ContentLoader,
  },

  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: "",
      pages: "user-view",
    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("user/getData");
      await store.dispatch("user/getDataPermission");
      await store.dispatch("menu/getMenu");
      await store.dispatch("auth/currentPermission");
      const permissions = localStorage.getItem("permission");
      console.log("ini permission", permissions);

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const users = computed(() => {
      return store.getters["user/getData"];
    });

    const menus = computed(() => {
      //panggil getter dengan nama "getOrder" di module "order" vuex
      return store.getters["menu/getMenu"];
    });

    onMounted(() => {
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("user/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("user/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("user/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
      });

      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    function confirmDelete(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch("user/destroy", id).then(() => {
            store.dispatch("user/getData");
            //
            // router.push({ name: "admin-user" });
            router.push({ name: "admin-user" });
            //alert
            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Dihapus!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    }

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      users,
      menus,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      datapermissions,
    };
  },
};
</script>
